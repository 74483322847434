<template>
  <div>
    <v-toolbar color="blue" elevation="5" height="45">
      <v-tabs color="white" grow v-model="tab">
        <v-tabs-slider color="yellow"></v-tabs-slider>
        <v-tab :to="path_toDraw">おえかき
          <v-icon>mdi-brush</v-icon>
        </v-tab>
        <v-tab :to="path_toList">ずかん
          <v-icon>mdi-penguin</v-icon>
        </v-tab>
        <v-tab :to="path_toForm" value="first">ほーむ
          <v-icon>mdi-account</v-icon>
        </v-tab>
      </v-tabs>
    </v-toolbar>
  </div>
</template>

<script>
// import Drawing from "./drawing.vue";

export default {
  props: ['aquarium'],
  name: "aaa",

  data: () => ({
    // tab: `/${this.$store.state.aquarium}/form`,
    tab: 'first',  //初期値のセット
  }),
  methods: {
  },
  computed: {
    path_toForm() {
      return `/${this.$store.state.aquarium}/form`; // ダイナミックにパスを生成
    },
    path_toDraw() {
      return `/${this.$store.state.aquarium}/draw-penguin`; // ダイナミックにパスを生成
    },
    path_toList() {
      return `/${this.$store.state.aquarium}/list`;
    }
  }
};
</script>