<template>
  <v-app>
    <v-main>
      <Header></Header>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Header from "./components/top-bar.vue";

export default {
  // props: ['aquarium'],
  name: "App",

  data: () => ({
    //
  }),
  components: {
    Header,
  },
};
</script>
