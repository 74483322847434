import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    name: 'guest',
    mail: 'guest',
    aquarium: '',
    penName: '',
    aquaId: 1,
    bandColor: [],
    imgUrl: null,
    drawTime: 0,
    data: null,
    sumidaColors: [{
        id: 1,
        text: "Pink",
        icon: "mdi-circle",
        color: "pink",
      },
      {
        id: 2,
        text: "Blue",
        icon: "mdi-circle",
        color: "blue",
      },
      {
        id: 3,
        text: "Red",
        icon: "mdi-circle",
        color: "red",
      },
      {
        id: 4,
        text: "White",
        icon: "mdi-circle",
        color: "#F5F5F5",
      },
      {
        id: 5,
        text: "Purple",
        icon: "mdi-circle",
        color: "purple",
      },
      {
        id: 6,
        text: "Yellow",
        icon: "mdi-circle",
        color: "yellow",
      },
      {
        id: 7,
        text: "Brown",
        icon: "mdi-circle",
        color: "brown",
      },
      {
        id: 8,
        text: "Green",
        icon: "mdi-circle",
        color: "green",
      },
      {
        id: 9,
        text: "Light Green",
        icon: "mdi-circle",
        color: "light-green",
      },
      {
        id: 10,
        text: "Gray",
        icon: "mdi-circle",
        color: "gray",
      },
      {
        id: 11,
        text: "Pink2",
        icon: "mdi-circle",
        color: "pink",
      },
      {
        id: 12,
        text: "Purple2",
        icon: "mdi-circle",
        color: "purple",
      },
      {
        id: 13,
        text: "Blue2",
        icon: "mdi-circle",
        color: "blue",
      },
      {
        id: 14,
        text: "Brown2",
        icon: "mdi-circle",
        color: "brown",
      },
      {
        id: 15,
        text: "Green2",
        icon: "mdi-circle",
        color: "green",
      },
      {
        id: 16,
        text: "Light Green2",
        icon: "mdi-circle",
        color: "light-green",
      },
      {
        id: 17,
        text: "Red2",
        icon: "mdi-circle",
        color: "red",
      },
    ],
    kyotoColors: [{
        text: "Brown",
        icon: "mdi-circle",
        color: "brown",
      },
      {
        text: "Red",
        icon: "mdi-circle",
        color: "red",
      },
      {
        text: "Pink",
        icon: "mdi-circle",
        color: "pink",
      },
      {
        text: "Orange",
        icon: "mdi-circle",
        color: "orange",
      },
      {
        text: "Yellow",
        icon: "mdi-circle",
        color: "yellow",
      },
      {
        text: "Light Green",
        icon: "mdi-circle",
        color: "light-green",
      },
      {
        text: "Green",
        icon: "mdi-circle",
        color: "green",
      },
      {
        text: "Blue",
        icon: "mdi-circle",
        color: "blue",
      },
      {
        text: "Light Blue",
        icon: "mdi-circle",
        color: "light-blue",
      },
      {
        text: "Purple",
        icon: "mdi-circle",
        color: "purple",
      },
      {
        text: "Gray",
        icon: "mdi-circle",
        color: "gray",
      },
      {
        text: "Clear",
        icon: "mdi-circle",
        color: "#E1F5FE",
      },
      {
        text: "White",
        icon: "mdi-circle",
        color: "#F5F5F5",
      },
      {
        text: "Brown2",
        icon: "mdi-circle",
        color: "brown",
      },
      {
        text: "Pink2",
        icon: "mdi-circle",
        color: "pink",
      },
      {
        text: "Orange2",
        icon: "mdi-circle",
        color: "Orange",
      },
      {
        text: "Yellow2",
        icon: "mdi-circle",
        color: "yellow",
      },
      {
        text: "Purple2",
        icon: "mdi-circle",
        color: "purple",
      },
      {
        text: "White2",
        icon: "mdi-circle",
        color: "#F5F5F5",
      },
    ],
    rank_data: [],
    part: [1,1,1,1,1,1,1,1,1],
    user: null,
  },
  mutations: {
    setName(state, val) {
      state.name = val.name
    },
    setMail(state, val) {
      state.mail = val.mail
    },
    setAquarium(state, val) {
      state.aquarium = val.aquarium
    },
    setPenName(state, val) {
      state.penName = val.penName
    },
    setBandcolor(state, val) {
      state.bandColor = val.bandColor
    },
    setRankData(state, val) {
      state.rank_data = val.rank_data
    },
    setUser(state, user) {
      state.user = user
    }
  },
  actions: {},
  modules: {}
})